const feture = {
  base_info_success:'Product Information Verification Successful',
  prd_trace_success:'Traceability Information Validation Successful',
  spt_info_success:'Supplier Information Validation in Progress',
  collect:'Collapse',
  expand:'Expand',
  prd_info:'Product Information',
  bc_rd:'Blockchain Record',
  brd_info:'Brand Information',
  logistics_info: "Logistics Info",
  quca_lince:'Qualifications and Licenses',
  trace_info:'Traceability information',
  prd_rpt:'Production Report',
  blc_dept:'Data Stored on Blockchain',
  dis_now:'Explore Now',
  sub_failed:'Submission failed, please resubmit data',
  fill_msg:'Leave a Message',
  no_data:'No data available',
  chk_review:'Your submission is under review, please check later.',
  ecs_bless:'Personalized Message',
  prd_tag:'Product ID',
  prd_search:'Scan History',
  enquiries:'Number of Scans',
  modal_bless:'Leave a Message',
  choose_form:'Please select the your Message type.',
  skip:'Skip',
  photo_bless:'Image',
  fill_wish: 'Leave a personalized message',
  vioce_bless:'Voice Message',
  video_bless:'Video Blessing',
  plse_sel_video:'Upload video',
  plse_sel_photo:'Upload photo',
  plse_add_voice:'Upload audio file',
  please_title:'Please enter a subject',
  please_content:'Please fill in the message content',
  next:'Next',
  smt:'Submit',
  bck_dpt:'Blockchain Ledger',
  prd_code:'Product Code',
  upload_id:'Upload by',
  upload_time:'Upload Time',
  block:'Block Info',
  bck_time:'Block Creation Time',
  trace_num:'Transaction Number',
  miss_vid:'VID Missing',
  miss_customId: 'customId does not exist',
  msg:'Message',
  img_empty_tip:'Please upload your image.',
  video_empty_tip:'Please upload your video.',
  voice_tip:'Please upload your audio file.',
  msg_empty:'Please fill in the subject of your Message.',
  content_empty:'Message cannot be empty.',
  failed_get_msg:'Failed to retrieve Message details.',
  failed_get_code:'Failed to retrieve Traceability details',
  failed_get_brand:'Failed to retrieve Brand Info',
  records:'Scan History',
  tag_searched:'This ID has been queried',
  time:'times',
  first_check:'Initial Scan Time',
  first_location:'Initial Scan Location',
  no_record:'No scan history found.',
  more:'More',
  no_more:'No more data found',
  query:'nums query',
  no:'No. of Scan',
  scan_record_failed:'Failed to retrieve Scan History',
  prd_loc:'Production Location',
  audio_error:'Incompatible format. Please upload an mp3 file.',
  reupload:'Please try upload again.',
  uploading:'Uploading...' ,
  again:'Please try again.',
  upload_failed:'Upload failed, please try again.',
  get_sku_failed:'Failed to retrieve SKU template data, please try again.',
  params_error:'Parameter error, please check',
  create_success:'Message created successfully',
  token_failed:'Data analysis failed',
  failed_cert_deposit:'Failed to get blockchain certificate',
}

export default feture;
