export default {
  operator: '员工',
  console: '管理员',
  account: '账号',
  blankAccount: '请输入账户名',
  password: '密码',
  blankPwd: '请输登录密码',
  verifyCode: '验证码',
  blankVerifyCode: '请输验证码',
  chkAgreament: '我已阅读 | 服务协议 | 隐私政策',
  readAgrement: '请先阅读并同意服务协议和隐私政策',
  signIn: '登录',
  signOut: '退出登录',
  logged: '已登录，马上进入',
  old: '旧版本登录',
  title: '登录 ToolChain'
}