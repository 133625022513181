export default {
  menuname: 'Data Nodes Management',
  button_detail: 'Details',
  buttom_text_operation: 'Operation Information',
  buttom_text_blockchain: 'Ledger Data Information',
  buttom_text_verification: 'Review Information',
  description_custom_id:
    'ID list can only be entered with the same type of ID (e.g., if VID has been entered, custom IDs cannot be added, clear all IDs to reselect the ID type)',
  custom_id: 'Custom ID',
  vid: 'VID',
  empty_id_list_hint: 'Please click the Add button in the upper right corner to add an ID',
  add_id_dialog_mode_qr: 'QR Code',
  add_id_dialog_mode_chip: 'Chips',
  add_id_dialog_title: 'Input tags',
  add_id_bigvid_title: 'Input Batch',
  add_id_dialog_type: 'Input method',
  add_id_dialog_order_type: 'Select an Order',
  add_id_dialog_custom_id_valid: 'Total Available',
  add_id_dialog_custom_id_use: 'Number of IDs selected',
  add_id_dialog_start_end_type: 'Input Custom ID range',
  add_id_dialog_input_type: 'Input Custom ID',
  add_id_dialog_beginning_id: 'Starting ID',
  add_id_dialog_end_id: 'Ending ID',
  add_id_dialog_beginning_id_placeholder: 'Please enter the starting ID',
  add_id_dialog_end_id_placeholder: 'Please enter the ending ID',
  add_id_dialog_end_error_id: 'Custom ID is not legal or has been used',
  add_batch_dialog_end_error_id: 'Please scan the batch code to receive',
  add_id_dialog_limit_error: 'The number has exceeded the upper limit',
  dcp_ref_title: 'Data Reference',
  add_error_id_has_been_used: 'The ID has been used',
  dcp_ref_filter_name: 'Search by name',
  dcp_ref_filter_model: 'All templates',
  dcp_ref_table_item_model: 'Template Name',
  dcp_ref_table_item_org: 'Organization Name',
  dcp_ref_all_table_title: 'Referenced Data',
  dcp_ref_added_table_title: 'Selected data',
  gps_refresh_button: 'Refresh',
  no_receive_permissions: "No receiving permission",
}
