export default {
  setting_data: "Settings",
  setting_data1: "data",
  operation: "operation",
  manage: "Manage",
  service: "service",
  loading: "Loading",
  btn_buy: "Purchase",
  title_hint: "Hint",
  btn_immediately_to: "Go immediately",
  to_create: "Go to create",
  btn_delete: "Delete",
  btn_delete_all: "Delete all the {item}",
  btn_delete_error: "Delete the wrong {item}",
  btn_add_item: "Add {item}",
  status_remove: "Remove",
  status_added: "Added",
  btn_update: "Upgrade version",
  btn_operate: "Configure Employee",
  btn_configuration: "Configure",
  btn_confirm: "Confirm",
  btn_search: "Search",
  btn_detail: "Details",
  btn_view: "View",
  btn_cancel: "Cancel",
  btn_close: "Close",
  btn_Next: "Next",
  btn_Up: "Previous",
  required_error: "{item} cannot be empty!" ,
  required_error_limit_mini_text: "Cannot be less than {item} characters",
  max_error: "Only {max} characters can be entered",
  btn_save: "Save",
  btn_edit: "Edit",
  btn_submit: "Submit",
  btn_download: "Download",
  btn_reject: "Reject",
  tip: "Tip",
  view_more: "View More",
  back: "Back",
  date_start: "Start time",
  date_end: "End time",
  latlon_set: "Longitude: {lon} Latitude: {lat}",

  nodata: "No data available at this time",
  tap_title_input: "Enter VID",
  tap_title_order: "Select VID",

  operator_add_file: "Add file",
  operator_add_processing: "Processing please wait..." ,
  operator_add: "Adding",

  picker_options_draft: "Draft",
  picker_options_submitted: "Submitted",

  message_import_succeed: "Import successful",
  message_import_uploading: "Importing..." ,
  message_import_wait: "Pending import",
  message_import_over: "Number of VIDs exceeded 10000",
  message_import_subvid_fail: "Small code does not exist",
  message_import_big_fail: "Large code does not exist",
  message_import_sku_fail: "SKU does not exist",
  message_import_vid_fail: "VID does not exist",
  message_import_batch_fail: "Batch information matching error",
  message_import_fail: "Import failed",
  message_import_type_error: "The imported file type is wrong",
  message_import_type_over10: "The file tag cannot exceed 10",
  message_import_file_error: "Imported file content error",
  message_import_file_size_limit: "File size limit {size}Mb",

  common_button_cancel: "Cancel",
  common_button_confirm: "OK",
  common_button_create: "Create",
  common_button_create_blank: "Create with empty template",
  common_button_create_random: "Create deep random template",
  common_button_edit: "Edit",
  common_button_settings: "Go to settings",
  common_button_lock: "Lock",
  common_button_more: "More",
  common_button_view: "View",
  common_button_synchronous: "Synchronous",
  common_button_save: "Save",
  common_button_save_submit: "Save draft",
  common_button_close: "Close",
  common_button_upload: "Upload",
  common_button_uploading: "Uploading",
  common_button_select: "Please select",
  common_button_download: "Download",
  common_button_preview: "Preview",
  common_button_back: "Back",
  common_button_addversion: "Add version",
  common_button_new: "New {orgName}",
  button_reset_comfirm: "Confirm",

  table_area_operation: "operation",
  table_title_uuid: "UUID",
  table_title_modelname: "Name",
  table_title_creator: "Creator",
  table_title_date: "Creation time",
  table_title_name: "Name",
  table_title_address: "Address",
  table_title_company: "Company Name",
  table_title_project: "Project Name",
  table_title_skuname: "SKU Name",
  table_title_discription: "Discription",
  table_title_status: "Status",
  table_title_createtime: "Creation time",
  table_title_modifytime: "Operation time",
  table_title_version: "Versions",
  table_title_account: "Account",
  table_title_lastversion: "Latest Version",
  table_title_information: "Information",
  table_title_manager: "Manager",
  table_title_datacollection: "Data Collection Name",
  table_title_includedata: "Included Data",
  table_title_blockchainstatus: "BlockChain Status",
  table_title_datarecord: "Data Record Name",
  table_title_nodename: "Node Name",
  table_title_operator: "Operator",
  table_title_vid: "Data-VID",
  table_title_hash: "Hash",
  table_title_verificationhash: "Verification Hash",
  table_title_tradingid: "HxID",
  table_title_tradingindex: "Index",
  table_title_packagename: "Process Name",
  table_title_verificationname: "Service Name",
  table_title_datamodel: "Data Model",
  table_title_stylemodel: "Style",
  table_title_verification: "Verification Status",
  table_title_worker: "Worker",
  table_title_recordname: "Record Name",
  table_title_auditbu: "Audit BU",
  table_title_auditor: "Auditor",
  table_title_audittime: "Audit Time",
  table_title_filename: "File Name",
  table_title_packageid: "Process ID",
  table_title_verificationid: "Service ID",
  table_title_packagetype: "Type",
  table_title_verificationtype: "Type",
  table_title_enterprise: "Company Name",
  table_title_addenterprise: "Added companies",
  table_title_published_settings: "Public Settings",
  table_title_published_enterprise: "Public companies",
  table_title_buname: "Company Name",
  table_title_bufrom: "Attributed Companies",
  table_title_updatename: "Name",
  table_title_number_applications: "Number of applications",
  table_title_id_range_mapping: "Starting and ending yardage segments",
  table_title_current_number: "Quantity used",
  table_title_available_number: "Available Quantity",
  table_title_idType: "ID Type",
  table_title_id: "ID",
  table_title_remark: "Remarks",
  button_setting_remove_vid: "Delete the wrong vid",
  button_setting_index: "Set as default",
  button_setting_remove: "Delete",

  message_create_success: "Created Successfully",
  message_update_success: "Update Success",
  message_upload_succeed: "Upload successful",

  components_table_status_PROCESSING: "Committing",
  components_table_status_EXAMINE_PROCESSING: "Committing",
  components_table_status_SUBMIT_INIT: "Committing",
  components_table_status_DRAFT_SUCCESS: "Draft",
  components_table_status_DRAFT_SAVED: "Draft",
  components_table_status_SAVING: "Committing",
  components_table_status_SAVE_FAILURE: "Failed to submit",
  components_table_status_SAVED: "Committed",
  components_table_status_ONCHAIN_PROCESSING: "Uploading",
  components_table_status_ONCHAIN_NO: "Not on chain",
  components_table_status_ONCHAIN_SUCCESS: "Upchaining successful",
  components_table_status_DRAFT: "Draft",
  components_table_status_SUBMITED: "Submitted",
  components_table_status_SUBMIT_SUCCESS: "Submitted successfully",
  components_table_status_SUBMIT: "Submitted",
  components_table_status_ONCHAIN: "Uploading",
  components_table_status_SUCCESS: "Upload successful",
  components_table_verify_NO: "Not verified",
  components_table_verify_PROCESSING: "Verifying",
  components_table_verify_PASS: "Approved",
  components_title_availabel: "Number of available VIDs",
  components_title_select: "Number of VIDs used",
  components_head_vid_number: "Number of VIDs",
  components_head_export_url_settings: "URL settings",
  components_title_selectorder: "Select order",
  components_button_enter: "Enter VID",
  components_message_add: "Click enter to add IDs to the list after you finish typing",
  components_message_invalid: 'Wrong VID',
  components_message_delete: 'Duplicate VIDs, please delete',
  components_message_err_delete: 'Illegal ID, please delete',
  components_message_repeat: 'Duplicate VIDs, please re-enter',
  table_title_availiable: "Available Quantity",
  table_title_total: "Total",

  picker_time_to: "to",
  picker_time_starttime: "starttime",
  picker_time_endtime: "Cutoff time",
  picker_time_lastweek: "Last week",
  picker_time_lastmonth: "Last month",
  picker_time_last3month: "Last three months",

  verify_on_block_chain: "Go to Thor blockchain to verify",

  operator_watch: "View",
  operator_lock: "Blocking",
  operator_verification: "Review results",
  operator_unlock: "Unblock",
  operator_published: "Public Settings",
  operator_skuCopy: "Copy",
  operator_export: "Export",
  operator_import: "Import",
  product_name: "Product Name",
  product_count: "Quantity",
  batch_info: "Batch Info"
};
