export default {
  setting_data: "设置",
  setting_data1: "数据",
  operation: "操作",
  manage: "管理",
  service: "服务",
  loading: "加载中",
  btn_buy: "采购",
  title_hint: "提示",
  btn_immediately_to: "立即前往",
  to_create: "前往创建",
  btn_delete: "删除",
  btn_delete_all: "删除全部的{item}",
  btn_delete_error: "删除错误的{item}",
  btn_add_item: "新增{item}",
  status_remove: "移除",
  status_added: "已添加",
  btn_update: "升级版本",
  btn_operate: "配置员工",
  btn_configuration: "配置",
  btn_confirm: "确认",
  btn_search: "搜索",
  btn_detail: "详情",
  btn_view: "查看",
  btn_cancel: "取消",
  btn_close: "关闭",
  btn_Next: "下一步",
  btn_Up: "上一步",
  required_error: "{item} 不能为空!",
  required_error_limit_mini_text: "不能低于 {item} 个字符",
  max_error: "最多只能输入{max}个字符",
  btn_save: "保存",
  btn_edit: "编辑",
  btn_submit: "提交",
  btn_download: "下载",
  btn_reject: "拒绝",
  tip: "提示",
  view_more: "查看更多",
  back: "返回",
  date_start: "起始时间",
  date_end: "结束时间",
  latlon_set: "经度: {lon} 纬度: {lat}",

  nodata: "暂无数据",
  tap_title_input: "输入VID",
  tap_title_order: "选择VID",

  operator_add_file: "添加文件",
  operator_add_processing: "正在处理请稍后...",
  operator_add: "添加",

  picker_options_draft: "草稿",
  picker_options_submitted: "已提交",

  message_import_succeed: "导入成功",
  message_import_uploading: "正在导入...",
  message_import_wait: "待导入",
  message_import_over: "VID数量超过10000",
  message_import_subvid_fail: "小码不存在",
  message_import_big_fail: "大码不存在",
  message_import_sku_fail: "SKU不存在",
  message_import_vid_fail: "VID不存在",
  message_import_batch_fail: "批次信息比对错误",
  message_import_fail: "导入失败",
  message_import_type_error: "导入的文件类型错误",
  message_import_type_over10: "文件标签不能超过10个",
  message_import_file_error: "导入的文件内容错误",
  message_import_file_size_limit: "文件大小限制{size}Mb",

  common_button_cancel: "取消",
  common_button_confirm: "确定",
  common_button_create: "创建",
  common_button_create_blank: "以空模版创建",
  common_button_create_random: "创建深度随机模版",
  common_button_edit: "编辑",
  common_button_settings: "前往设置",
  common_button_lock: "锁定",
  common_button_more: "更多",
  common_button_view: "查看",
  common_button_synchronous: "同步",
  common_button_save: "保存",
  common_button_save_submit: "保存草稿",
  common_button_close: "关闭",
  common_button_upload: "上传",
  common_button_uploading: "上传中",
  common_button_select: "请选择",
  common_button_download: "下载",
  common_button_preview: "预览",
  common_button_back: "返回",
  common_button_addversion: "添加版本",
  common_button_new: "新建{orgName}",
  button_reset_comfirm: "确认",

  table_area_operation: "操作",
  table_title_uuid: "UUID",
  table_title_modelname: "名称",
  table_title_creator: "创建者",
  table_title_date: "创建时间",
  table_title_name: "名称",
  table_title_address: "Address",
  table_title_company: "企业名称",
  table_title_project: "项目名称",
  table_title_skuname: "SKU Name",
  table_title_discription: "Discription",
  table_title_status: "状态",
  table_title_createtime: "创建时间",
  table_title_modifytime: "操作时间",
  table_title_version: "版本",
  table_title_account: "账户",
  table_title_lastversion: "最新版本",
  table_title_information: "信息",
  table_title_manager: "Manager",
  table_title_datacollection: "Data Collection Name",
  table_title_includedata: "Included Data",
  table_title_blockchainstatus: "BlockChain Status",
  table_title_datarecord: "Data Record Name",
  table_title_nodename: "节点名称",
  table_title_operator: "操作员",
  table_title_vid: "Data-VID",
  table_title_hash: "Hash",
  table_title_verificationhash: "Verification Hash",
  table_title_tradingid: "HxID",
  table_title_tradingindex: "Index",
  table_title_packagename: "Process Name",
  table_title_verificationname: "服务名称",
  table_title_datamodel: "Data Model",
  table_title_stylemodel: "Style",
  table_title_verification: "Verification Status",
  table_title_worker: "Worker",
  table_title_recordname: "Record Name",
  table_title_auditbu: "Audit BU",
  table_title_auditor: "Auditor",
  table_title_audittime: "Audit Time",
  table_title_filename: "File Name",
  table_title_packageid: "Process ID",
  table_title_verificationid: "服务 ID",
  table_title_packagetype: "类型",
  table_title_verificationtype: "类型",
  table_title_enterprise: "企业名称",
  table_title_addenterprise: "已添加企业",
  table_title_published_settings: "公开设置",
  table_title_published_enterprise: "公开企业",
  table_title_buname: "企业名称",
  table_title_bufrom: "归属企业",
  table_title_updatename: "名称",
  table_title_number_applications: "申请数量",
  table_title_id_range_mapping: "起止码段",
  table_title_current_number: "已用数量",
  table_title_available_number: "可用数量",
  table_title_idType: "ID类型",
  table_title_id: "ID",
  table_title_remark: "备注",
  button_setting_remove_vid: "删除错误的vid",
  button_setting_index: "设为默认",
  button_setting_remove: "删除",

  message_create_success: "创建成功",
  message_update_success: "更新成功",
  message_upload_succeed: "上传成功",

  components_table_status_PROCESSING: "提交中",
  components_table_status_EXAMINE_PROCESSING: "提交中",
  components_table_status_SUBMIT_INIT: "提交中",
  components_table_status_DRAFT_SUCCESS: "草稿",
  components_table_status_DRAFT_SAVED: "草稿",
  components_table_status_SAVING: "提交中",
  components_table_status_SAVE_FAILURE: "提交失败",
  components_table_status_SAVED: "已提交",
  components_table_status_ONCHAIN_PROCESSING: "上链中",
  components_table_status_ONCHAIN_NO: "不上链",
  components_table_status_ONCHAIN_SUCCESS: "上链成功",
  components_table_status_DRAFT: "草稿",
  components_table_status_SUBMITED: "已提交",
  components_table_status_SUBMIT_SUCCESS: "提交成功",
  components_table_status_SUBMIT: "已提交",
  components_table_status_ONCHAIN: "上链中",
  components_table_status_SUCCESS: "上链成功",
  components_table_verify_NO: "不审核",
  components_table_verify_PROCESSING: "审核中",
  components_table_verify_PASS: "审核通过",
  components_title_availabel: "可用VID数量",
  components_title_select: "使用VID数量",
  components_head_vid_number: "VID数量",
  components_head_export_url_settings: "URL设置",
  components_title_selectorder: "选择订单",
  components_button_enter: "输入VID",
  components_message_add: "输入完成后点击回车键添加ID到列表",
  components_message_invalid: 'VID错误',
  components_message_delete: '重复的VID,请删除后提交',
  components_message_err_delete: '非法的ID,请删除后提交',
  components_message_repeat: 'VID重复,请重新输入',
  table_title_availiable: "可用数量",
  table_title_total: "总数",

  picker_time_to: "至",
  picker_time_starttime: "开始时间",
  picker_time_endtime: "截止时间",
  picker_time_lastweek: "最近一周",
  picker_time_lastmonth: "最近一个月",
  picker_time_last3month: "最近三个月",

  verify_on_block_chain: "前往雷神区块链验证",

  operator_watch: "查看",
  operator_lock: "封存",
  operator_verification: "审核结果",
  operator_unlock: "解封",
  operator_published: "公开设置",
  operator_skuCopy: "复制",
  operator_export: "导出",
  operator_import: "导入",
  product_name: "商品名称",
  product_count: "数量",
  batch_info: "批次信息"
};
