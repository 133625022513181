export default {
  message_default: "服务异常",
  common_network_error: "网络异常，请稍后重试",
  "tc.supplychainapp.accountnoExist": "账号已存在",
  "tc.common.RequestParamsEmptyException": "请检查必填参数",
  "tc.supplychainapp.labelNotFound": "码段错误",
  "tc.supplychainapp.label": "码段已绑定",
  "tc.supplychainapp.goodsNotExist": "商品不存在",
  "tc.supplychainapp.orderNotExist": "订单不存在",
  "tc.supplychainapp.orderNotComplated": "订单未完成",
  "tc.supplychainapp.asnOrderConfigNotSet": "订单未完成",
  "tc.supplychainapp.batchNoNotExist": "批次不存在",
  "tc.supplychainapp.batchNoHasUsed": "批次已经被使用",
  "tc.supplychainapp.accountNotExist": "账号不存在",
  "tc.supplychainapp.dataArchived": "数据已经被冻结",
  "tc.supplychainapp.organizationDisabled": "机构已经停用",
  "tc.supplychainapp.accountDisabled": "账户已经停用",
  "tc.supplychainapp.labelCannotBeUsed": "标签不可用",
  "tc.supplychainapp.labelCannotBeChanged": "标签不可被修改",
  "tc.supplychainapp.labelIsBound": "标签已经绑定",
  "tc.access.InvalidTokenException": "token 无效，请申请token",
  "tc.access.NoOperationPermissionException": "无权限操作",
  "tc.access.TokenEmptyException": "token为空，请申请token",
  "tc.account.ApplyExistedException": "申请不存在",
  "tc.account.ApplyExpiredException": "申请过期",
  "tc.account.ApplyStatusException": "申请错误",
  "tc.account.IdentityExistedException": "身份已存在",
  "tc.account.LoginTokenInvalidException": "令牌无效",
  "tc.account.UserAndPasswordIncorrectException": "密码不正确或身份不存在",
  "tc.bu.BizDataOnProcessingException": "企业信息处理中",
  "tc.bu.BizExistedException": "企业名称已存在",
  "tc.bu.BizNotExistedException": "企业信息不存在",
  "tc.bu.BUDataExistedException": "企业名称已存在",
  "tc.bu.BUNotExistedException": "企业不存在",
  "tc.bu.DataExistedException": "企业管理请求号已存在",
  "tc.bu.DataModelNotExistedException": "datamodel不存在",
  "tc.bu.DataNotExistedException": "数据中心配置不存在",
  "tc.bu.DataOnProcessingException": "正在处理其他任务，请稍后再试",
  "tc.bu.EnvironmentInvalidException": "服务异常，请联系管理员",
  "tc.bu.ExistedProjectException": "项目名称已存在",
  "tc.bu.InvalidBUException": "企业为非正常状态",
  "tc.bu.InvalidDataStatusException": "企业状态异常",
  "tc.bu.InvalidProjectException": "项目不存在或停用",
  "tc.bu.InvalidProjectStatusException": "项目状态异常",
  "tc.bu.OperationException": "企业创建失败",
  "tc.bu.PackageExistedException": "流程已存在",
  "tc.bu.ProjectExistedException": "项目名称已存在",
  "tc.bu.ProjectNotExistedException": "项目不存在",
  "tc.bu.ProjectStatusException": "项目状态异常",
  "tc.bu.ServiceVendorContractExistedException": "供应商的服务合约已存在",
  "tc.bu.ServiceVendorContractNotExistedException": "供应商的服务合约不存在",
  "tc.bu.ServiceVendorNotExistedException": "服务供应商不存在",
  "tc.bu.SmartcontractNotExistedException": "合约不存在",
  "tc.bu.TemplateInstanceNotExistedException": "项目流程不存在",
  "tc.bu.TemplateInstanceTypeException": "流程类型错误",
  "tc.bu.TemplateNotExistedException": "流程不存在",
  "tc.bu.TemplatePackageNotExistedException": "流程不存在",
  "tc.channel.AccountInvalidException": "AppID不存在",
  "tc.channel.InvalidSmartcontractException": "无效的智能合约",
  "tc.channel.SmartcontractExistedException": "存证合约已创建。",
  "tc.commodities.CategoryNotExistedException": "商品类别不存在",
  "tc.commodities.CommodityIsOfflineException": "商品不存在",
  "tc.commodities.CommodityNotExistedException": "商品不存在",
  "tc.commodities.CommodityNotPermitedException": "无权限操作该商品",
  "tc.commodities.OrderingIDNotExistedException": "订单错误",
  "tc.commodities.OutServiceTerritoryException": "该服务地区不支持该商品",
  "tc.commodities.OverweightException": "商品超重，请重新选择",
  "tc.commodities.PaymentTypeNotSelectedException": "请选择订单支付方式",
  "tc.commodities.PaymentTypeNotSupportException": "支付方式不存在",
  "tc.commodities.SupportLanguageException": "商品语言未找到",
  "tc.commodities.TCCCommodityNotExisted": "商品未配置TCC数量",
  "tc.commodities.UpdateCommondityException": "更新商品信息失败",
  "tc.common.BizException": "系统异常，请稍后再操作",
  "tc.common.ConfigNotFoundException": "配置信息找不到",
  "tc.common.DataExistedException": "数据已存在",
  "tc.common.DataNotExistedException": "记录不存在",
  "tc.common.DataNotonChainException": "数据未上链",
  "tc.common.InvalidRequest": "无效的请求",
  "tc.common.InvalidRequestParamsException": "传入的参数无效",
  "tc.common.LoginTokenInvalidException": "令牌验证失败",
  "tc.common.NetworkException": "网络异常，请稍后重试",
  "tc.common.NoPermissionException": "没有访问权限",
  "tc.common.OperationException": "系统异常，请稍后再操作",
  "tc.common.RequestParamsInvalidException": "异常，参数不合法",
  "tc.common.ServiceMaintain": "服务维护中，请稍后重试",
  "tc.common.Success": "请求成功",
  "tc.console.BizException": "服务超时，请稍后再试",
  "tc.console.UserAndPasswordIncorrectException": "密码不正确或用户名不存在",
  "tc.data.DataExpiredException": "下载文件不能超过1个月",
  "tc.data.DataNotExistedException": "下载文件不存在，请联系管理员",
  "tc.data.DataTypeNotSupportException": "文件上传类型有误",
  "tc.data.InvalidDataOperationException": "服务超时，请稍后再试",
  "tc.datamodel.FileNotFoundException": "流程文件未找到",
  "tc.datamodel.LockedException": "流程已被使用，不能更改",
  "tc.developer.AlreadyAuthorizedUIDException": "用户uid已经赋权",
  "tc.developer.ApiIdNotExist": "API id 不存在",
  "tc.developer.AppIDDisableException": "appid已被禁用",
  "tc.developer.AppIDExistedException": "appid已存在",
  "tc.developer.AppIDNoContractPermissionException": "应用无智能合约访问权限",
  "tc.developer.AppIDNotExistedException": "appid不存在",
  "tc.developer.CertExpiredException": "证书文件已过期",
  "tc.developer.CertNotExistedException": "证书未找到，请重新申请",
  "tc.developer.CertRevokedException": "证书文件已被吊销",
  "tc.developer.CsrUploadedException": "CSR上传失败，请重试",
  "tc.developer.CustomerUIDExistedException": "用户uid已存在",
  "tc.developer.DataIllegalException": "数据合规校验失败",
  "tc.developer.ExceedAppIDLimitedException": "超过最大可创建appid数",
  "tc.developer.MobileAppExceedLimitException": "app包超过上限。",
  "tc.developer.MobileAppExistedException": "应用包名重复",
  "tc.developer.MobileAppNotAuthorizedException": "应用包签名未找到",
  "tc.developer.OperatorNoContractPermissionException": "uid无访问合约权限",
  "tc.developer.OriginTextTooLargeException": "原文数据过大(>10KB)",
  "tc.developer.SignatureInvalidException": "签名错误",
  "tc.developer.SignNotCorrectException": "签名不合法",
  "tc.developer.SmartcontractNotExistedException": "默认合约未找到",
  "tc.developer.TCCInsufficientException": "TCC不足",
  "tc.developer.TokenInvalidException": "token为空",
  "tc.developer.VIDHashDuplicationException": "vid hash重复",
  "tc.goods.DataNotExistedException": "商品获取失败",
  "tc.opaccount.AccountCreatingInProgressException": "账户正在创建",
  "tc.opaccount.AccountDisabledException": "账户被禁用，请联系管理员",
  "tc.opaccount.AccountExistedException": "账号已存在",
  "tc.opaccount.AccountFrozenException": "账户被锁定",
  "tc.opaccount.AccountNotExistedException": "账号不存在",
  "tc.opaccount.DataNotExistedException": "系统异常(4004)，请稍后再操作",
  "tc.opaccount.EmailExistedException": "邮箱已存在，更换邮箱",
  "tc.opaccount.InvalidDataOperationException": "系统异常，请稍后再操作",
  "tc.opaccount.InvalidPasswordException": "密码不正确",
  "tc.opaccount.NoPermissionException": "权限不存在",
  "tc.opaccount.RoleExistedException": "角色名称已存在",
  "tc.opaccount.RoleNotExistedException": "角色不存在",
  "tc.opaccount.SimplePasswordException": "密码太简单",
  "tc.opaccount.TeleExistedException": "手机号已存在，请更换手机号",
  "tc.order.DailyLimitOrderException": "采购数量超过日配额",
  "tc.order.InvalidAddressException": "请填写收货信息",
  "tc.order.InvalidPermissionException": "沙盒环境不支持芯片采购",
  "tc.order.InvalidRegionException": "您选择的地区不存在，请联系管理员",
  "tc.order.OperationException": "订单提交失败，请联系管理员",
  "tc.order.OrderNotExistedException": "订单不存在，请重新选择订单",
  "tc.order.OrderOverWeightException": "订单已超重",
  "tc.order.RequestLimitOrderException": "采购数量超过日配额",
  "tc.packagemanage.InvalidTempPackageException": "无效流程",
  "tc.payment.InsufficientTCCException": "TCC数量不足，请采购TCC",
  "tc.security.ForbiddenDataException": "图片文件不合法",
  "tc.security.RepeatSubmitException": "重复提交",
  "tc.security.TooBigDataException": "上传文件超过最大限制",
  "tc.security.TooManyRequestException": "上传次数超过限制，请明日再试",
  "tc.standardapp.ActivityNameExistedException": "展示页面信息名字已存在，请重新填写。",
  "tc.standardapp.ActivityNotFoundException": "展示页面记录不存在，请重新选择展示信息。",
  "tc.standardapp.BatchNotFoundException": "未找到Batch，请新建或选择已有Batch。",
  "tc.standardapp.BatchNameExistedException": "Batch名称已存在",
  "tc.standardapp.BindedRelationExistedException": "已绑定，不能重复绑定",
  "tc.standardapp.CollectionVIDBindingSKUException":"VID属于集合VID，不可用于绑定SKU，请重新选择VID",
  "tc.standardapp.DataExistedException": "绑定记录已经提交，不允许修改",
  "tc.standardapp.DataNotExistedException": "记录不存在，请重新选择",
  "tc.standardapp.DataOnProcessingException": "业务正在处理中，请稍后重试",
  "tc.standardapp.EmptyRequestJsonException": "请求Json不可为空，请重试",
  "tc.standardapp.InvalidBatchOperationException": "此批次正在被使用，请更换批次",
  "tc.standardapp.InvalidDataOperationException": "操作有误，请重新填写",
  "tc.standardapp.InvalidRequestNoException": "操作有误，请重新填写",
  "tc.standardapp.InvalidRequestParamsException": "参数错误，请重新填写",
  "tc.standardapp.InvalidSKUBoundException": "SKU绑定记录不存在，请重新绑定SKU",
  "tc.standardapp.InvalidVIDException": "VID未找到，请重新选择VID",
  "tc.standardapp.MaxVIDBindedLengthException": "子码个数超过限制，请选择新的父码。",
  "tc.standardapp.MaxVIDTreeDepthException": "集合层级超过限制",
  "tc.standardapp.NoBindedRelationExistedException": "无绑定关系，无法解绑",
  "tc.standardapp.NoPermissionException": "无权限",
  "tc.standardapp.OrderNotBelongToBUException": "订单不属于BU或项目，请重新选择VID",
  "tc.standardapp.SKUArchivedException": "SKU已封存，请重新选择SKU",
  "tc.standardapp.SKUBindedInfoOnProcessingException": "此次绑定操作在进行中，请稍后再试。",
  "tc.standardapp.SKUDraftStatusException": "SKU为草稿状态，请提交",
  "tc.standardapp.SKUExistedException": "SKU编码有误",
  "tc.standardapp.SKUNotExistedException": "SKU不存在，请重新选择",
  "tc.standardapp.SKUSealStatusException": "SKU为封存状态，请重新选择SKU",
  "tc.standardapp.VIDBindedException": "VID已被绑定，请重新选择VID",
  "tc.standardapp.VIDBindSkuException": "VID已绑定SKU，请重新选择VID",
  "tc.standardapp.VIDListNotExistedException": "VID不存在，请重新选择VID",
  "tc.standardapp.VIDNotBelongToProjectException": "VID不属于BU或项目，请重新选择VID",
  "tc.standardapp.VIDNotBindedException": "VID未绑定，请重新选择VID",
  "tc.standardapp.VIDPermissionException": "VID不属于该企业，请重新选择VID",
  "tc.standardapp.vidOccupyError": "VID重复或被抢占",
  "tc.standardapp.VIDListException": "VID错误",
  "tc.standardapp.DataExamineException": "填写的内容有不符合政府法规的敏感词，请检查后重新提交",
  "tc.verification.DataNotExistedException": "审核信息不存在",
  "tc.verification.DataOnProcessingException": "审核信息正常处理中",
  "tc.verification.DataResubmitException": "审核信息已存在",
  "tc.verification.DataReverifyException": "信息已被审核",
  "tc.verification.RecordNameExistedException": "审核报告名称重复，请重新命名",
  "tc.vid.InvalidVIDException": "VID无效",
  "tc.webapi.AppIDNameExceedLengthException": "API自定义名称长度超过【】",
  "tc.webapi.AppIDOperationException": "APPID操作出错",
  "tc.webapi.CertNotExistedException": "证书未找到，请重新申请",
  "tc.webapi.DCPAPIFileZipException": "ProcessAPI文件生成失败",
  "tc.webapi.DCPGetModelException": "Process DCP model获取失败",
  "tc.webapi.ExportingExistedException": "正在进行其它导出vid任务，请稍后再试",
  "tc.webapi.InvalidSKUException": "无效的sku",
  "tc.webapi.NoOperationPermissionException": "无权限操作",
  "tc.webapi.OperationException": "导出失败，请重试。",
  "tc.webapi.PackageNameExistedException": "应用包名重复",
  "tc.webapi.UploadCSRException": "CSR上传失败，请重试",
  "tc.webapi.VerifiedCodeException": "验证码有误",
  "tc.webapi.VerifiedCodeExpiredException": "验证码已过期",
  "tc.webapi.WEBSDKKEYException": "websdk创建/更新失败",
  "tc.webapi.WhitelistExceedLimitException": "IP白名单超过数量上限",
  "tc.workbench.DataOnProcessingException": "处理中…",
  "tc.workbench.NoPermissionException": "无权限",
  "tc.workbench.VerifiedCodeErrorException": "验证码有误",
  "tc.workbench.VerifiedCodeExpiredException": "验证码已过期",
  "tc.operator.NotMatchEnvironment": "页面环境和登录信息不匹配，请退回到项目列表，刷新页面",
  "tc.operator.ChecklistInstanceNoPermission": "无权限",
  "tc.standardapp.CustomIdNotExistException": "ID不存在",
}
