export default {
  menu_order:'order',
  dashboard: 'dashboard',
  main: 'MAIN',
  business_management: 'BUSINESS MANAGEMENT',
  shipping: 'Shipping',
  product: 'Create Product',
  sku_group: 'Product Management',
  label: 'Label Management',
  account_management: 'ACCOUNT MANAGEMENT',
  group: 'AGENCY MANAGEMENT',
  delivery: 'Shipping',
  receive: 'Receive',
  user: 'Employee management',
}
