import Vue from 'vue'
import VueI18n from 'vue-i18n'

import enEle from 'element-ui/lib/locale/lang/en.js'
import zhEle from 'element-ui/lib/locale/lang/zh-CN.js'

import locale from 'element-ui/lib/locale'
import zh from './cn'
import en from './en'

import { storage  } from '@/utils'
import { commonStoreModule } from '@/store/module/common'
import { parseQueryStr } from '@/utils/common'

Vue.use(VueI18n)

const SYS_LANGUAGE = commonStoreModule.langLocale

const DEFAULT_LANG = "en" //通过浏览器设置的默认语言
const locales = {
  zh: zh,
  en: en
}

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales
})
const init = Vue.prototype._init
Vue.prototype._init = function(options: any) {
  init.call(this, {
    i18n,
    ...options
  })
}

export const setup = () => {
  const { language } = parseQueryStr(window.location.search);
  const lang = !language ? 'zh' : language;  // 默认语言为中文

  locale.use(lang === 'zh' ? zhEle : enEle)
  Object.keys(locales).forEach(lang => {
    document.body.classList.remove(`lang-${lang}`)
  })
  document.body.classList.add(`lang-${lang}`)
  document.body.setAttribute('lang', lang)
  i18n.locale = lang
  storage(SYS_LANGUAGE).set(lang)
}

setup()

export default i18n
