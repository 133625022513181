const app = {
  scan_chip_title: '获取芯片标签',
  scan_access_mode: '获取方式',
  single_code: '单码',
  start_stop_code: '起止码',
  scan_qr_tag_title: '获取二维码标签',
  scan_dialog_title: '获取标签信息',
  scan_qr_tag: '二维码标签',
  scan_result_total: '总计',
  scan_result_success: '成功',
  scan_result_fail: '失败',
  nfc_msg: '请将NFC设备靠近芯片读取数据',
  rfid_msg: '请开启 UHF RFID读取芯片数据',
  infrar_msg: '请点击扫码枪按钮读取二维码数据',
  qr_msg: '请扫描二维码读取数据',
  barcode_msg: '请扫描条形码读取数据',
  qrcode_single_msg: '请扫描二维码读取数据',
  barcode_single_msg: '请扫描条形码读取数据',
  qrcode_multi_msg: '请扫描二维码读取数据',
  barcode_multi_msg: '请扫描条形码读取数据',
  btn_save: '保存',
  btn_save_draft: '保存草稿',
  btn_submit: '提交',
  btn_clear: '取消',
  btn_confirm: '确认',
  btn_add: '继续',
  no_data: '暂无数据',
  no_logistics_data: '暂无物流信息',
  btn_increase: '添加',
  please_entry_tag: '标签信息不能为空，请输入标签信息',
  nfc: 'NFC',
  rfid: 'UHF RFID',
  qr: '摄像头',
  infrar: '扫码枪',
  single: 'VID单扫',
  multi: 'VID群扫',
  customid_single: '自定义ID单扫',
  customid_multi: '自定义ID群扫',
  customid_range: '自定义ID起止',
  id_type_custom: '自定义ID',
  id_type_vid: 'VID',
  qrcode_single: '二维码单扫',
  qrcode_multi: '二维码群扫',
  barcode_single: '条形码单扫',
  barcode_multi: '条形码群扫',
  choose_dialog_title: '设置',
  nfc_single: '芯片标签',
  qr_customid_single: '二维码 (单扫录入)',
  // qr_customid_multi: '二维码 (群扫录入)',
  qr_customid_multi: '输入标签',
  manual_label_entry: '手动输入标签',
  please_entry_tag_tip: '请输入标签',
  please_entry_start: '请输入标签起始码',
  please_entry_end: '请输入标签终止码',
  // qr_customid_range: '二维码 (起止码录入)',
  qr_customid_range: '二维码标签',
  scan_nfc_info: '请将设备靠近芯片读取数据',
  scan_qr_info: '请扫描二维码读取数据',
  del_all: '清除失败',
  scan_limit: '已扫VID到达最大支持数',
  scan_content_overflow: '已扫内容到达最大支持数',
  upload_pdf: '点击上传PDF',
  upload_img: '点击上传图片',
  upload_video: '点击上传视频',
  upload_audio: '点击上传音频',
  required: '必填',
  max_valid: '长度不超过{max}',
  update: '更新',
  switch_true: '是',
  switch_false: '否',
  add_ref_data_button: '选择数据',
  components_render_placeholder_filter: "过滤器",
  required_error: "必填",

  project_internal: '本项目DCP',
  project_external: '外部项目DCP',
  dcp_search_placeholder: '请输入名称或企业名称模糊搜索',
  dcp_data_name: '数据名称',
  dcp_bu: '企业',
  dcp_project: '项目',
  dcp_type: 'DCP类型',
  dcp_update_date: '更新日期',
  dcp_advanced_search: '高级搜索',
  dcp_search_cancel: '取消',
  dcp_start_time: '起始日期',
  dcp_end_time: '终止日期',
  dcp_data_info: '数据信息',
  dcp_data_update: '更新数据',

  dcp_cancel: '取消',
  dcp_confirm: '确定',
  dcp_options_placeholder: '请选择',
  dcp_filter_name: '名称',
  dcu_close: '关闭',
  dcu_confirm: '确定',
  viderror: {
    1003: "无效请求参数异常",
    1300300046: "Vid错误",
    1300301046: "Vid列表为空",
    1300300040: "Vid错误",
    1300300029: "您的余额不足，请采购TCC或者联系运营人员",
    1300301045: "校验失败",
    1300300042: "大码已经被使用",
    1300440000: "审核信息不存在",
    1300440004: "审核信息不存在",
    1300440001: "审核信息正在处理中",
    1300440002: "信息已被审核",
    1300440003: "审核信息已存在",
    1300440005: "审核报告名称已存在，请重新命名",
    1300440007: "数据无效",
    1300000000: "提交失败，你的内容包含违规违法信息（如黄赌毒）",
    1300300037: "集合绑定深度太多，请重新新建集合绑定",
    1001: "网络异常，请重试",
    1002: "服务器异常",
    2003: "芯片读取失败。请再次将手机贴近芯片后，稍作停留等待读取芯片信息",
    2005: "芯片校验失败",
    2009: "芯片读取失败。请再次将手机贴近芯片后，稍作停留等待读取芯片信息",
    3001: "芯片读取失败。请再次将手机贴近芯片后，稍作停留等待读取芯片信息",
    3005: "芯片可能被复制",
    3006: "芯片读取失败。请再次将手机贴近芯片后，稍作停留等待读取芯片信息",
    3007: "芯片读取失败。请再次将手机贴近芯片后，稍作停留等待读取芯片信息",
    3008: "芯片读取失败。请再次将手机贴近芯片后，稍作停留等待读取芯片信息",
    4001: "芯片读取失败。请再次将手机贴近芯片后，稍作停留等待读取芯片信息",
    4100: "芯片读取失败。请再次将手机贴近芯片后，稍作停留等待读取芯片信息",
    1005: "芯片ID未找到，请确认订单是否收货！",
    9012: "芯片读取失败。请再次将手机贴近芯片后，稍作停留等待读取芯片信息",
    100003: "APP签名错误",
    100004: "签名证书错误",
    100006: "AppID不存在",
    10001: "芯片暂未收货，请确认收货",
    10002: "ID已绑定请更换ID",
    50000: "芯片状态异常，请换新的芯片扫描",
    50001: "暂不支持此芯片录入，请使用web订单录入",
    default: "校验失败"
  },
  vid_startingcode: "起始码:",
  vid_endingcode: "终止码:",
  scaned_id_list: "ID列表",
  delete_execption_id: "移除异常ID",
  delete_all_id: "移除所有ID",
  table_title_availiable: "可用数量",
  table_title_total: "总数",
  add_id_dialog_custom_id_valid: '可用数量',
  button_reset_comfirm: "确认",
  common_button_back: "返回",
  common_button_cancel: "取消",
  add_id_dialog_custom_id_use: '使用数量',
  gps_signal_weak: "GPS信号弱",
  select_label_type: "请选择录入标签型号",
  tag_list:"标签列表",
  delete: "移除",
  all_del: "全部移除",
  batch_info: "获取批次信息",
  select_batch_label: "请选择录入批次信息",
  qr_batch_info: "获取二维码批次信息",
  batch_list: "批次列表",
  scan_batch_info_title: '获取芯片批次信息',
  batch_qr_customid_single: '二维码批次',
  batch_nfc_single: '芯片批次',
  batch_qr_single: '输入批次',
  manual_input_batch: '手动获取批次信息',
  please_entry_batch: '批次信息不能为空，请输入',
  please_batch: '请输入批次信息',
  dcp_error_400015: 'TCC不足请联系相关人员进行充值',
  receive: '收货',
  received: '已收货',
  no_code: '二维码标签或芯片不存在',
  get_ID_list: '获取ID清单',
  no_start_end_code: '起止码不允许为空'
}

export default app;
