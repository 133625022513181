export default {
  menu_order:'订单',
  dashboard: '仪表盘',
  main: 'MAIN',
  business_management: 'BUSINESS MANAGEMENT',
  shipping: 'Shipping',
  product: '创建产品',
  sku_group: '产品管理',
  label: '标签管理',
  account_management: 'ACCOUNT MANAGEMENT',
  group: '机构管理',
  delivery: '发货',
  receive: '收货',
  user: '员工管理',
}
