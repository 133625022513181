export default {
  message_default: "服务异常",
  common_network_error: "网络异常，请稍后重试",
  "tc.supportchainapp.accountnoExist": "Account already exists",
  "tc.common.RequestParamsEmptyException": "Please check required parameters",
  "tc.supportchainapp.labelNotFound": "Code field error",
  "tc.supportchainapp.label": "Code segment is bound",
  "tc.supplychainapp.goodsNotExist": "Product does not exist",
  "tc.supplychainapp.orderNotExist": "Order does not exist",
  "tc.supplychainapp.orderNotComplated": "Order not completed",
  "tc.supplylychainapp.asnOrderConfigNotSet": "Order not completed",
  "tc.supplychainapp.batchNoNotExist": "Batch does not exist",
  "tc.supplychainapp.batchNoHasUsed": "Batch has been used",
  "tc.supplychainapp.accountNotExist": "Account does not exist",
  "tc.supplylychainapp.dataArchived": "Data has been frozen",
  "tc.supportchainapp.organizationDisabled": "Organization has been deactivated",
  "tc.supplylychainapp.accountDisabled": "Account has been deactivated",
  "tc.supportchainapp.labelCannotBeUsed": "Label is not available",
  "tc.supportchainapp.labelCannotBeChanged": "Label cannot be modified",
  "tc.supportchainapp.labelIsBound": "Label is bound",
  "tc.access.InvalidTokenException": "token is invalid, please request a token",
  "tc.access.NoOperationPermissionException": "No permission operation",
  "TokenEmptyException": "token is empty, please request a token",
  "tc.account.ApplyExistedException": "Application does not exist",
  "tc.account.ApplyExpiredException": "Application expired",
  "ApplyStatusException": "Application error",
  "tc.account.IdentityExistedException": "Identity already exists",
  "tc.account.LoginTokenInvalidException": "Invalid token",
  "tc.account.UserAndPasswordIncorrectException": "Incorrect password or identity does not exist",
  "tc.bu.BizDataOnProcessingException": "Enterprise information is being processed",
  "tc.bu.BizExistedException": "Business name already exists",
  "tc.bu.BizNotExistedException": "Enterprise information does not exist",
  "tc.bu.BUDataExistedException": "Business name already exists",
  "tc.bu.BUNotExistedException": "Business does not exist",
  "tc.bu.DataExistedException": "Enterprise management request number already exists",
  "tc.bu.DataModelNotExistedException": "datamodel does not exist",
  "tc.bu.DataNotExistedException": "Data center configuration does not exist",
  "tc.bu.DataOnProcessingException": "Working on another task, please try again later",
  "tc.bu.EnvironmentInvalidException": "Service exception, please contact administrator",
  "tc.bu.ExistedProjectException": "Project name already exists",
  "tc.bu.InvalidBUException": "Enterprise is abnormal",
  "tc.bu.InvalidDataStatusException": "Enterprise status exception",
  "tc.bu.InvalidProjectException": "Project does not exist or is deactivated",
  "tc.bu.InvalidProjectStatusException": "Project status exception",
  "tc.bu.OperationException": "Enterprise creation failed",
  "tc.bu.PackageExistedException": "Process already exists",
  "tc.bu.ProjectExistedException": "Project name already exists",
  "tc.bu.ProjectNotExistedException": "Project does not exist",
  "tc.bu.ProjectStatusException": "Project status exception",
  "tc.bu.ServiceVendorContractExistedException": "Vendor's service contract already exists",
  "tc.bu.ServiceVendorContractNotExistedException": "The service contract of the provider does not exist",
  "tc.bu.ServiceVendorNotExistedException": "Service provider does not exist",
  "tc.bu.SmartcontractNotExistedException": "Contract does not exist",
  "tc.bu.TemplateInstanceNotExistedException": "Project process does not exist",
  "tc.bu.TemplateInstanceTypeException": "Process type error",
  "tc.bu.TemplateNotExistedException": "Process does not exist",
  "tc.bu.TemplatePackageNotExistedException": "Process does not exist",
  "tc.channel.AccountInvalidException": "AppID does not exist",
  "tc.channel.InvalidSmartcontractException": "Invalid smartcontract",
  "tc.channel.SmartcontractExistedException": "The deposited contract has been created." ,
  "tc.commodities.CategoryNotExistedException": "Commodity category does not exist",
  "tc.commodities.CommodityIsOfflineException": "Commodity does not exist",
  "tc.commodities.CommodityNotExistedException": "Commodity does not exist",
  "tc.commodities.CommodityNotPermitedException": "No permission to operate this item",
  "tc.commodities.OrderingIDNotExistedException": "Order error",
  "tc.commodities.OutServiceTerritoryException": "The item is not supported in this service area",
  "tc.commodities.OverweightException": "Item is overweight, please reselect",
  "tc.commodities.PaymentTypeNotSelectedException": "Please select order payment method",
  "tc.commodities.PaymentTypeNotSupportException": "Payment method does not exist",
  "tc.commodities.SupportLanguageException": "Product language not found",
  "tc.commodities.TCCCommodityNotExisted": "Product is not configured with TCC quantity",
  "tc.commodities.UpdateCommondityException": "Failed to update commodity information",
  "tc.common.BizException": "System exception, please operate again later",
  "tc.common.ConfigNotFoundException": "Configuration information not found",
  "tc.common.DataExistedException": "Data already exists",
  "tc.common.DataNotExistedException": "Record does not exist",
  "tc.common.DataNotonChainException": "Data is not chained",
  "tc.common.InvalidRequest": "Invalid request",
  "tc.common.InvalidRequestParamsException": "Invalid incoming parameters",
  "tc.common.LoginTokenInvalidException": "Token authentication failed",
  "tc.common.NetworkException": "Network exception, please try again later",
  "tc.common.NoPermissionException": "No access privilege",
  "tc.common.OperationException": "System exception, please try again later",
  "tc.common.RequestParamsInvalidException": "Exception, parameter is not legal",
  "tc.common.ServiceMaintain": "Service maintenance in progress, please try again later",
  "tc.common.Success": "Request was successful",
  "tc.console.BizException": "Service timed out, please try again later",
  "tc.console.UserAndPasswordIncorrectException": "Incorrect password or username does not exist",
  "tc.data.DataExpiredException": "Download file cannot be older than 1 month",
  "tc.data.DataNotExistedException": "Download file does not exist, please contact administrator",
  "tc.data.DataTypeNotSupportException": "Wrong file upload type",
  "tc.data.InvalidDataOperationException": "Service timeout, please try again later",
  "tc.datamodel.FileNotFoundException": "Process file not found",
  "tc.datamodel.LockedException": "The process is already in use and cannot be changed",
  "tc.developer.AlreadyAuthorizedUIDException": "User uid has been authorized",
  "tc.developer.ApiIdNotExist": "API id does not exist",
  "tc.developer.AppIDDisableException": "appid has been disabled",
  "tc.developer.AppIDExistedException": "appid already exists",
  "tc.developer.AppIDNoContractPermissionException": "App does not have smart contract access",
  "tc.developer.AppIDNotExistedException": "appid does not exist",
  "tc.developer.CertExpiredException": "Certificate file has expired",
  "tc.developer.CertNotExistedException": "Certificate not found, please reapply",
  "tc.developer.CertRevokedException": "The certificate file has been revoked",
  "tc.developer.CsrUploadedException": "CSR upload failed, please try again",
  "tc.developer.CustomerUIDExistedException": "User uid already exists",
  "tc.developer.DataIllegalException": "Data compliance verification failed",
  "tc.developer.ExceedAppIDLimitedException": "Exceeded the maximum number of appid that can be created",
  "tc.developer.MobileAppExceedLimitException": "App package exceeded limit." ,
  "tc.developer.MobileAppExistedException": "Duplicate app package name",
  "tc.developer.MobileAppNotAuthorizedException": "App package signature not found",
  "tc.developer.OperatorNoContractPermissionException": "uid does not have access to the contract",
  "tc.developer.OriginTextTooLargeException": "Original text data is too large (>10KB)",
  "tc.developer.SignatureInvalidException": "Signature error",
  "SignatureNotCorrectException": "Signature is not legal",
  "tc.developer.SmartcontractNotExistedException": "Default contract not found",
  "tc.developer.TCCInsufficientException": "TCC insufficient",
  "tc.developer.TokenInvalidException": "token is empty",
  "tc.developer.VIDHashDuplicationException": "vid hash duplicate",
  "tc.goods.DataNotExistedException": "Product fetch failed",
  "tc.opaccount.AccountCreatingInProgressException": "Account is being created",
  "AccountDisabledException": "Account is disabled, please contact administrator",
  "tc.opaccount.AccountExistedException": "Account already exists",
  "tc.opaccount.AccountFrozenException": "Account is locked",
  "tc.opaccount.AccountNotExistedException": "Account does not exist",
  "tc.opaccount.DataNotExistedException": "System exception (4004), please operate later",
  "tc.opaccount.EmailExistedException": "Mailbox already exists, change mailbox",
  "InvalidDataOperationException": "System exception, please operate later",
  "InvalidPasswordException": "Password is incorrect",
  "tc.opaccount.NoPermissionException": "Permission does not exist",
  "tc.opaccount.RoleExistedException": "Role name already exists",
  "tc.opaccount.RoleNotExistedException": "Role does not exist",
  "tc.opaccount.SimplePasswordException": "Password is too simple",
  "tc.opaccount.TeleExistedException": "Mobile phone number already exists, please change it",
  "tc.order.DailyLimitOrderException": "Purchase quantity exceeds daily quota",
  "InvalidAddressException": "Please fill in the receiving information",
  "InvalidPermissionException": "The sandbox environment does not support chip procurement",
  "tc.order.InvalidRegionException": "The region you selected does not exist, please contact the administrator",
  "tc.order.OperationException": "Order submission failed, please contact administrator",
  "tc.order.OrderNotExistedException": "Order does not exist, please select order again",
  "tc.order.OrderOverWeightException": "Order is overweight",
  "tc.order.RequestLimitOrderException": "Purchase quantity exceeds daily quota",
  "tc.packagemanage.InvalidTempPackageException": "Invalid process",
  "tc.payment.InsufficientTCCException": "Insufficient TCC quantity, please procure TCC",
  "tc.security.ForbiddenDataException": "Image file is not legal",
  "tc.security.RepeatSubmitException": "Repeat submission",
  "tc.security.TooBigDataException": "Upload file exceeds maximum limit",
  "tc.security.TooManyRequestException": "The number of uploads exceeded the limit, please try again tomorrow",
  "tc.standardapp.ActivityNameExistedException": "Display page information name already exists, please refill it." ,
  "tc.standardapp.ActivityNotFoundException": "The display page record does not exist, please reselect the display information." ,
  "tc.standardapp.BatchNotFoundException": "Batch not found, please create new or select existing Batch.",
  "tc.standardapp.BatchNameExistedException": "Batch name already exists",
  "tc.standardapp.BindedRelationExistedException": "Binded, cannot repeat binding",
  "tc.standardapp.CollectionVIDBindingSKUException": "VID belongs to collection VID and cannot be used to bind SKU, please select VID again",
  "tc.standardapp.DataExistedException": "Binding record has been submitted, modification is not allowed",
  "tc.standardapp.DataNotExistedException": "Record does not exist, please reselect",
  "tc.standardapp.DataOnProcessingException": "Business is being processed, please try again later",
  "tc.standardapp.EmptyRequestJsonException": "Request Json cannot be empty, please try again",
  "tc.standardapp.InvalidBatchOperationException": "This batch is being used, please change the batch",
  "tc.standardapp.InvalidDataOperationException": "There is an error in the operation, please fill it in again",
  "tc.standardapp.InvalidRequestNoException": "There is an error in the operation, please fill in again",
  "tc.standardapp.InvalidRequestParamsException": "Parameter error, please refill",
  "tc.standardapp.InvalidSKUBoundException": "SKU binding record does not exist, please rebind SKU",
  "tc.standardapp.InvalidVIDException": "VID not found, please reselect VID",
  "tc.standardapp.MaxVIDBindedLengthException": "The number of child codes exceeded the limit, please select a new parent code.",
  "tc.standardapp.MaxVIDTreeDepthException": "Collection hierarchy exceeded limit",
  "tc.standardapp.NoBindedRelationExistedException": "No binding relationship, unable to unbind",
  "tc.standardapp.NoPermissionException": "No permission",
  "tc.standardapp.OrderNotBelongToBUException": "Order does not belong to BU or project, please reselect VID",
  "tc.standardapp.SKUArchivedException": "SKU is blocked, please reselect SKU",
  "tc.standardapp.SKUBindedInfoOnProcessingException": "This binding operation is in progress, please try again later.",
  "tc.standardapp.SKUDraftStatusException": "SKU is in draft status, please submit",
  "tc.standardapp.SKUExistedException": "SKU encoding is wrong",
  "tc.standardapp.SKUNotExistedException": "SKU does not exist, please reselect",
  "tc.standardapp.SKUSealStatusException": "SKU is blocked, please reselect SKU",
  "tc.standardapp.VIDBindedException": "VID is bound, please reselect VID",
  "tc.standardapp.VIDBindSkuException": "VID has been bound SKU, please reselect VID",
  "tc.standardapp.VIDListNotExistedException": "VID does not exist, please reselect VID",
  "tc.standardapp.VIDNotBelongToProjectException": "VID does not belong to BU or project, please reselect VID",
  "tc.standardapp.VIDNotBindedException": "VID is not bound, please reselect VID",
  "tc.standardapp.VIDPermissionException": "VID does not belong to the enterprise, please reselect VID",
  "tc.standardapp.vidOccupyError": "VID is duplicated or preempted",
  "tc.standardapp.VIDListException": "VID error",
  "tc.standardapp.DataExamineException": "The filled content has sensitive words that do not comply with government regulations, please check and resubmit",
  "tc.verification.DataNotExistedException": "Audit information does not exist",
  "tc.verification.DataOnProcessingException": "The audit information is being processed normally",
  "tc.verification.DataResubmitException": "The audit information already exists",
  "tc.verification.DataReverifyException": "The message has been verified",
  "tc.verification.RecordNameExistedException": "Duplicate audit report name, please rename",
  "tc.vid.InvalidVIDException": "VID is invalid",
  "tc.webapi.AppIDNameExceedLengthException": "API custom name length exceeded [ ]",
  "tc.webapi.AppIDOperationException": "APPID operation error",
  "tc.webapi.CertNotExistedException": "Certificate not found, please reapply",
  "tc.webapi.DCPAPIFileZipException": "ProcessAPI file generation failed",
  "tc.webapi.DCPGetModelException": "Process DCP model failed to get",
  "tc.webapi.ExportingExistedException": "Working on other exporting vid task, please try again later",
  "tc.webapi.InvalidSKUException": "Invalid sku",
  "tc.webapi.NoOperationPermissionException": "No permission operation",
  "tc.webapi.OperationException": "Export failed, please try again." ,
  "tc.webapi.PackageNameExistedException": "Duplicate application package name",
  "tc.webapi.UploadCSRException": "CSR upload failed, please try again",
  "tc.webapi.VerifiedCodeException": "Error in verification code",
  "tc.webapi.VerifiedCodeExpiredException": "Verified code has expired",
  "tc.webapi.WEBSDKKEYException": "websdk creation/update failed",
  "tc.webapi.WhitelistExceedLimitException": "IP whitelist exceeded maximum number",
  "tc.workbench.DataOnProcessingException": "Processing...",
  "tc.workbench.NoPermissionException": "No permission",
  "tc.workbench.VerifiedCodeErrorException": "Error in verification code",
  "tc.workbench.VerifiedCodeExpiredException": "Verified code has expired",
  "tc.operator.NotMatchEnvironment": "The page environment and login information do not match, please return to the project list and refresh the page",
  "tc.operator.ChecklistInstanceNoPermission": "No permission",
  "tc.standardapp.CustomIdNotExistException": "ID does not exist"
};
