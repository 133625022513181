
import network from '@/utils/network';
import * as common from '@/utils/common';
import axios from "axios";
import bless_network from '@/utils/blessNet';

class VeTrueApi {
  // 根据dcp-data-查询dcp详情
  getDcpDetail(data: any): Promise<any> {
    let headers = data.headers || {}
    return network.request({
      url: `/v1/project/dcp-data-detail` + '?dcp_data_id=' + data.bizDataId,
      method: 'get',
      data: data,
      headers
    })
  }

  // 根据 dcp_template_id 查询模版详情
  getTemplateDetail(data: any): Promise<any> {
    let headers = data.headers || {};
    return network.request({
      url: `/v1/project/template-detail` + '?dcp_id=' + data.dcpTemplateId,
      method: 'get',
      data: data,
      headers
    })
  }

  // 更新模版详情
  updateTemplate(data: any): Promise<any> {
    let headers = data.headers || {}
    return network.request({
      url: '/v1/project/template-update',
      method: 'post',
      data: data,
      headers
    })
  }

  // 拉取动态表单模版
  getSceneDcpModel(data: any): Promise<any> {
    let headers = data.headers || {};
    let query = common.createQueryStr(data.params);
    return network.request({
      url: `/v1/project/dcp-model` + `?${query}`,
      method: 'get',
      data: data,
      headers
    })
  }

  // 商品管理根据UUID拉取动态表单
  getDcpModelByUUID(data: any): Promise<any> {
    let headers = data.headers || {};
    let query = common.createQueryStr(data.params);
    return network.request({
      url: `/v1/project/dcp-model-by-uuid` + `?${query}`,
      method: 'get',
      data: data,
      headers
    })
  }

  // 提交动态表单
  submitDcpModel(data: any): Promise<any> {
    let headers = data.headers || {}
    return network.request({
      url: '/v1/project/create-dcp-data',
      method: 'post',
      data: data,
      headers
    })
  }

  // 更新dcp动态表单数据
  updateDcpModel(data: any): Promise<any> {
    let headers = data.headers || {}
    return network.request({
      url: '/v1/project/update-dcp-data',
      method: 'post',
      data: data,
      headers
    })
  }

  // 获取可用订单
  getUsableOrderList(data: any): Promise<any> {
    let headers = data.headers || {}
    let query = common.createQueryStr(data.params);
    return network.request({
      url: `/v1/project/usable-order` + `?${query}`,
      method: 'get',
      data: data,
      headers
    })
  }

  // 获取起止码列表
  getUsableTagList(data: any): Promise<any> {
    let headers = data.headers || {}
    // data.usable = true; // true 只返回未被使用的, 如果等于 false 使用过的也会返回
    return network.request({
      url: `/v1/project/usable-tag-list`,
      method: 'post',
      data: data,
      headers
    })
  }

  // 获取项目商品
  getProjectProduct(data: any): Promise<any> {
    let headers = data.headers || {}
    const query = data.search ? `&search=${data.search}` : '';
    return network.request({
      url: `/v1/project/usable-bind-product?project_uuid=${data.project_uuid}` + query,
      method: 'get',
      data: data,
      headers
    })
  }

  // 文件上传
  uploadFile(data: any): Promise<any> {
    const formData = new FormData();
    formData.append('file', data.file);

    const instance = axios.create({
      baseURL: window.Config.base_url,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return instance({
      url: `/v1/project/upload-file`,
      method: 'POST',
      headers: {
        'Content-Type':'multipart/form-data; boundary=----WebKitFormBoundaryAzX3RVXm4QFsI8lm',
        'Authorization': `Bearer ${data.query.token}`,
      },
      data: formData,
    })
  }

  // 根据ip获取经纬度地址
  getGpsInfo(data: any): Promise<any> {
    const gps = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return gps({
      url: 'https://ipgeo.veworld.com',
      method: 'post',
      headers: {
        'x-api-token': data.token,
        'content-type': 'application/json'
      },
      data
    })
  }

  // 无token文件上传
  uploadFileByVid(data: any): Promise<any> {
    const formData = new FormData();
    formData.append('file', data.file);

    const instance = axios.create({
      baseURL: window.Config.bless_service_url,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return instance({
      url: `/v1/public/service/upload-file/${data.vid}`,
      method: 'POST',
      headers: {
        'Content-Type':'multipart/form-data;',
      },
      data: formData,
    });
  }

  // 无token获取商品详情
  getProductDetail(data: any): Promise<any> {
    let headers = data.headers || {}
    return bless_network.request({
      url: `/v1/public/product-detail?vid=${data.vid}`,
      method: 'GET',
      data: data,
      headers
    })
  }

  // 无token获取赋码追溯详情
  getProductBindDetail(data: any): Promise<any> {
    let headers = data.headers || {}
    return bless_network.request({
      url: `/v1/public/product-bind-detail?vid=${data.vid}`,
      method: 'GET',
      headers,
    });
  }

   // 无token获取物流详情
   getLogistics(data: any): Promise<any> {
    let headers = data.headers || {}
    return bless_network.request({
      url: `/v1/public/logistics?search=${data.vid}`,
      method: 'GET',
      headers,
    });
  }

  // 无token获取品牌故事
  getBrandStory(data: any): Promise<any> {
    let headers = data.headers || {}
    return bless_network.request({
      url: `/v1/public/brand-story?vid=${data.vid}`,
      method: 'GET',
      headers,
    });
  }

  // 无token获取vid扫码记录
  getVidHistory(data: any): Promise<any> {
    let query = common.createQueryStr(data);
    const instance = axios.create({
      baseURL: window.Config.bless_service_url,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return instance({
      url: `/v1/public/vid-query-history` + `?${query}`,
      method: 'GET',
    });
  }

  // 无token提交生成寄语记录
  sendRemarkRecord(data: any): Promise<any> {
    const instance = axios.create({
      baseURL: window.Config.bless_service_url,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return instance({
      url: `/v1/public/service/remark-record`,
      method: 'POST',
      data: data,
    });
  }

  // 无token获取寄语记录详情
  getRecordDetail(data: any): Promise<any> {
    const instance = axios.create({
      baseURL: window.Config.bless_service_url,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return instance({
      url: `/v1/public/service/remark-record-detail?vid=${data.vid}`,
      method: 'GET',
    });
  }

  // 根据 txId 获取 transactions 信息
  getTransactionsInfo = (txId: any) => {
    if (!txId) return;
    // const txIdUrl = 'https://vethor-node.digonchain.com/transactions';
    // const txIdUrl = 'https://vethor-node-test.vechaindev.com/transactions';
    const txIdUrl = window.Config.chain_tx_url;
    const instance = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return instance({
      url: `${txIdUrl}/${txId}`,
      method: 'GET',
    });
  }

  // 根据 custom_id 找对应 vid
  getVidByCustomId = (data: any) => {
    let query = common.createQueryStr(data);
    return bless_network.request({
      url: `/v1/public/vid_by_custom_id` + `?${query}`,
      method: 'GET',
    })
  }

  // 收货方下拉查询来源
  getShipmentSelectList = (data: any) => {
    let query = common.createQueryStr(data.params);
    return network.request({
      url: `/v1/project/select-receive-department` + `?${query}`,
      data: data,
      method: 'get',
    })
  }

  // 可以发货的列表
  getProductBindSelectList = (data: any) => {
    let query = common.createQueryStr(data.params);
    return network.request({
      url: `/v1/project/product-bind-list` + `?${query}`,
      data: data,
      method: 'get',
    })
  }

  // 根据vid查询商品批次信息 收货管理中使用
  getProductBatchInfoByVid = (data: any) => {
    let query = common.createQueryStr(data.params);
    return network.request({
      url: `/v1/project/product-bind-detail` + `?${query}`,
      data: data,
      method: 'get',
    })
  }

  // 根据 batch_code 或者 data_id 查询收货管理数据
  getReceiveDataByBatchCodeOrReceiveId = (data: any) => {
    let query = common.createQueryStr(data.params);
    return network.request({
      url: `/v1/project/logistics-order-detail` + `?${query}`,
      data: data,
      method: 'get',
    })
  }
}


export default new VeTrueApi()
