export default {
  operator: 'User',
  console: 'Admin',
  account: 'Account',
  blankAccount: 'Please input your account',
  password: 'Password',
  blankPwd: 'Please input your password',
  verifyCode: 'Verify code',
  blankVerifyCode: 'Please input verify code',
  chkAgreament: 'I have read |Service agreement|Privacy policy',
  readAgrement: 'Please read and agree to the service agreement and privacy policy first',
  signIn: 'Sign in',
  signOut: 'Log out',
  logged: 'Already logged in, enter now',
  old: 'Old Version Login',
  title: 'Login ToolChain'
}