const app = {
  scan_chip_title: 'Chip Result',
  scan_access_mode: 'Access Mode',
  single_code: 'Single',
  start_stop_code: 'Start stop code',
  scan_qr_tag_title: 'Qr Code Result',
  scan_dialog_title: 'Result',
  scan_result_total: 'Total',
  scan_result_success: 'Finished Chips',
  scan_result_fail: 'Failed Chips',
  nfc_msg:
    'Please place the NFC device close to the chip and hold still to read it',
  rfid_msg: 'Please turn on UHF RFID to read the chip',
  infrar_msg: 'Please press the button on code scanner to read the QR code',
  qr_msg: 'Please scan the QR code to read the data',
  barcode_msg: 'Please scan the barcode to read the data',
  qrcode_single_msg: 'Please scan the QR code to read the data',
  barcode_single_msg: 'Please scan the barcode to read the data',
  qrcode_multi_msg: 'Please scan the QR code to read the data',
  barcode_multi_msg: 'Please scan the barcode to read the data',
  btn_save: 'Save',
  btn_save_draft: 'Save draft',
  btn_submit: 'Submit',
  btn_clear: 'Cancel',
  btn_confirm: 'Confirm',
  btn_add: 'Add more',
  no_data: 'No data',
  no_logistics_data: 'No logistics information',
  nfc: 'NFC',
  btn_increase: 'Add',
  please_entry_tag: 'Tag information cannot be empty, please enter the tag information',
  rfid: 'UHF RFID',
  qr: 'Camera',
  infrar: 'Barcode scanner',
  single: 'one VID',
  multi: 'multiple VID',
  customid_single: 'one custom ID',
  customid_multi: 'multiple custom ID',
  customid_range: 'custom ID range',
  id_type_custom: 'Custom ID',
  id_type_vid: 'VID',
  qrcode_single: 'one qrcode',
  qrcode_multi: 'multiple qrcode',
  barcode_single: 'one barcode',
  barcode_multi: 'multiple barcode',
  choose_dialog_title: 'Settings',
  nfc_single: 'Chip Tag',
  qr_customid_single: 'QrCode (Single Scan)',
  qr_customid_multi: 'QrCode (Multiple)',
  // qr_customid_range: 'QrCode (Range)',
  qr_customid_range: 'QrCode Tag',
  manual_label_entry: 'Manual label entry',
  please_entry_tag_tip: 'Please enter a tag',
  please_entry_start: 'Please enter the label start code',
  please_entry_end: 'Please enter the label termination code',
  scan_nfc_info: 'Please put the device close to the chip to read the data',
  scan_qr_info: 'Please scan the QR code to read the data',
  del_all: 'Erase all',
  scan_limit: 'Scanned VIDs have reached the maximum value',
  scan_content_overflow: 'Scanned content have reached the maximum value',
  upload_pdf: 'Click to Upload PDF',
  upload_img: 'Click to Upload Image',
  upload_video: 'Click to Upload Video',
  upload_audio: 'Click to Upload Audio',
  required: 'required',
  max_valid: 'length limit is {max}',
  update: 'Update',
  switch_true: 'True',
  switch_false: 'False',
  add_ref_data_button: 'Select data',
  components_render_placeholder_filter: "Filter",
  required_error: "Required",

  project_internal: 'My DCP',
  project_external: 'External DCP',
  dcp_search_placeholder: 'Search or quick search by company name',
  dcp_data_name: 'Name',
  dcp_bu: 'Company',
  dcp_project: 'Project',
  dcp_type: 'DCP type',
  dcp_update_date: 'Date',
  dcp_advanced_search: 'Advanced search',
  dcp_search_cancel: 'Cancel',
  dcp_start_time: 'Start date',
  dcp_end_time: 'End date',
  dcp_data_info: 'Data information',
  dcp_data_update: 'Data Updated',
  dcp_cancel: 'Cancel',
  dcp_confirm: 'Confirm',
  dcp_options_placeholder: 'Please select',
  dcp_filter_name: 'Name',
  dcu_close: 'Close',
  dcu_confirm: 'Confirm',
  viderror: {
    1003: "Invalid request params exception",
    1300300046: "Vids error",
    1300301046: "VidList is empty",
    1300300040: "Vids error",
    1300300029: "Insufficient balance. Please purchase TCC or contact our operations staff.",
    1300301045: "Check fail",
    1300300042: "Big vid has been used",
    1300440000: "Verification data is not existed",
    1300440004: "Verification data is not existed",
    1300440001: "Verification data is processing",
    1300440002: "Data has been verified",
    1300440003: "Verification data is existed",
    1300440005: "Verification report name is exists,please rename it",
    1300440007: "The data is invalid",
    1300000000: "Failed to submit. Your content contains illegal information (e.g. pornography, gambling or drugs)",
    1300300037: "Current group has been bound with multiple layers of data. You may create a new group.",
    1001: "Network abnormality, please try again",
    1002: "Server Exception",
    2003: "Chip reading failed. Please put the phone close to the chip again and wait for a while to read the chip information",
    2005: "Chip verification failure",
    2009: "Chip reading failed. Please put the phone close to the chip again and wait for a while to read the chip information",
    3001: "Chip reading failed. Please put the phone close to the chip again and wait for a while to read the chip information",
    3005: "Chip may be copied",
    3006: "Chip reading failed. Please put the phone close to the chip again and wait for a while to read the chip information",
    3007: "Chip reading failed. Please put the phone close to the chip again and wait for a while to read the chip information",
    3008: "Chip reading failed. Please put the phone close to the chip again and wait for a while to read the chip information",
    4001: "Chip reading failed. Please put the phone close to the chip again and wait for a while to read the chip information",
    4100: "Chip reading failed. Please put the phone close to the chip again and wait for a while to read the chip information",
    1005: "The chip ID is not found, please confirm whether the order has been received.",
    9012: "Chip reading failed. Please put the phone close to the chip again and wait for a while to read the chip information",
    100003: "APP signature error",
    100004: "Signing certificate error",
    100006: "AppID does not exist",
    10001: "ID has been bound please replace the ID",
    10002: "Chip has been bound please replace the chip",
    50000: "The chip status is abnormal, please replace it with a new one for scanning",
    50001: "This chip entry is not supported temporarily, please use web order entry",
    default: "Check fail"
  },
  vid_startingcode: "starting code:",
  vid_endingcode: "ending code:",
  scaned_id_list: "ID list",
  delete_execption_id: "remove execption ID",
  delete_all_id: "remove all ID",
  table_title_availiable: "Available Quantity",
  table_title_total: "Total",
  add_id_dialog_custom_id_valid: 'Available Quantity',
  button_reset_comfirm: "Confirm",
  common_button_back: "Back",
  common_button_cancel: "Cancel",
  add_id_dialog_custom_id_use: 'Number of uses',
  gps_signal_weak: "Weak GPS signal",
  select_label_type: "Please select the label type to be entered",
  tag_list:"Tag List",
  delete: "delete",
  all_del: "All Delete",
  batch_info: "Get batch info",
  select_batch_label: "Please select batch info",
  qr_batch_info: "Get QR Code batch info",
  batch_list: "Batch List",
  scan_batch_info_title: 'Get chip batch info',
  batch_qr_customid_single: 'QR Code Batch',
  batch_nfc_single: 'Chip Batch',
  batch_qr_single: 'Enter Batch',
  manual_input_batch: 'Get Batch Info manually',
  please_entry_batch: 'Batch Info cannot be empty, please enter',
  please_batch: 'Please enter Batch Info',
  dcp_error_400015: 'Insufficient TCC. Please order TCC or contact Customer Service.',
  receive: 'Receive',
  received: 'Received',
  no_code: 'QR Code or chip does not exist',
  get_ID_list: 'Get ID List',
  no_start_end_code: 'The starting and ending codes cannot be empty'
}
export default app;
