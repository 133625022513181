<template>
<div class="components_table_list">
  <el-row :gutter="20" type="flex" align="center">
    <el-col :span="24">
      <el-table :row-class-name="tableRowClassName" :data="tableInfo" v-loading="loading" style="width: 100%">
        <div slot="empty" style="line-height: 20px">{{ emptyText }}</div>
        <el-table-column style="position: relative;" v-for="(k, v) in setTableTitle(tableInfo)" :key="k + v" :prop="v" :label="k" :min-width="getMinWidth(v)">
          <template slot="header">
            <span>{{ k }}</span>
            <el-tooltip :content="$t('landingPageValidationSettingManage.default_view_page_list_warning')" placement="right">
              <i class="el-icon-question" v-if="v === 'isDefault'"></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <div class="table-item-content" v-if="v === 'status' && scope.row[v].includes('-')">
              <el-popover trigger="hover" placement="top">
                <p>{{ getErrorMessage(scope.row[v].split('-')[1]) }}</p>
                <div slot="reference" class="name-wrapper">
                  <span>{{ scope.row[v].split('-')[0] }}</span>
                  <i class="el-icon-warning-outline"></i>
                </div>
              </el-popover>
            </div>
            <div class="table-item-content" v-else-if="v === 'updatename' && scope.row[v].includes('-UPDATE')">
              <el-popover trigger="hover" placement="top">
                <p>{{ $t('checklistManage.hint_data_updated') }}</p>
                <div slot="reference" class="name-wrapper">
                  <span>{{ scope.row[v] ? scope.row[v].split('-UPDATE')[0] || '-' : '-' }}</span>
                  <i class="update-icon" :style="{ backgroundImage: `url(${imageUrl})` }" />
                </div>
              </el-popover>
            </div>
            <div class="table-item-content" v-else-if="v === 'isDefault' && scope.row[v] === 'true'">
              <i class="el-icon-check" style="font-size:14px"></i>
            </div>
            <div :class="isKeepAllCol(v) ? 'table-item-content-keep-all' : 'table-item-content'" v-else>
              {{ setVidShow(scope.row[v]) || '-' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :min-width="operationNumber * 56" :label="$t('common.table_area_operation')" v-if="tableInfo.length > 0 && operationNumber != 0">
          <template slot-scope="scope">
            <slot :slotData="scope"></slot>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  inject: ['vidCommon'],
  data() {
    return {
      tableHeight: 100,
      imageUrl: '',
      errorItem: [],
      successItem: [],
      warningItem: []
    }
  },
  computed: {
    errorCodeInfoList() {
      return this.vidCommon.error_list || []
    }
  },
  methods: {
    setVidShow(data) {
      if (!data) return '';
      if (data.length <= 20) return data;
      return data.replace(data.substring(10, data.length - 6), "......");
    },
    setTableTitle(data) {
      let tableTitle = {}
      try {
        Object.keys(data[0])
          .filter(item => item.indexOf('__') !== 0)
          .filter(item => item !== 'idType') // ID类型 这列删除
          .forEach(item => {
            tableTitle[item] = this.$t(`common.table_title_${item}`)
          })
        return tableTitle
      } catch (error) {
        return []
      }
    },
    tableRowClassName({
      row,
      rowIndex
    }) {
      if (row.remark) {
        return 'error-row'
      }
      if (this.errorItem.includes(rowIndex)) {
        return 'error-row'
      }
      if (this.successItem.includes(rowIndex)) {
        return 'success-row'
      }
      if (this.warningItem.includes(rowIndex)) {
        return 'warning-row'
      }
      return ''
    },
    getMinWidth(val) {
      let filterTableModel = this.tableModel.filter(item => item.prop === val) || []
      if (filterTableModel.length) {
        return filterTableModel[0].width
      }
    },
    isKeepAllCol(val) {
      let filterTableModel = this.tableModel.filter(item => item.prop === val) || []
      if (filterTableModel.length) {
        return filterTableModel[0].keepAll || false
      } else {
        return false
      }
    },
    getErrorMessage(code) {
      let filterErrorCodeInfoList = this.errorCodeInfoList.filter(item => {
        return item.code === code
      })
      if (filterErrorCodeInfoList.length > 0) {
        let errorCodeObject = filterErrorCodeInfoList[0]
        return errorCodeObject.message || this.$t('common.list_draft_error_unknow')
      }
    }
  },
  props: {
    tableInfo: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    operationNumber: {
      type: Number
    },
    loading: {
      type: Boolean
    },
    tableModel: {
      type: Array,
      default: () => []
    },
    emptyText: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>

.components_table_list>>>.success-row {
  background: $color-primary;
}

.components_table_list>>>.warning-row {
  background: $color-warning;
}

.components_table_list>>>.error-row {
  color: $color-warning;
}

.components_table_list {
  .table-item-content {
    border-right: 1px solid #dbdbdb;
    margin-right: 6px;
    word-break: break-all;
  }

  .table-item-content-keep-all {
    border-right: 1px solid #dbdbdb;
    margin-right: 6px;
  }
}

.update-icon {
  $size: 15px;
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: contain;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
}

.components_table_list>>>.cell {
  padding: 6px 0;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  position: relative;
  height: 100%;
  word-break: keep-all;
}
</style>
