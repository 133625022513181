export default {
  menuname: '数据节点管理',
  button_detail: '详情',
  buttom_text_operation: '操作信息',
  buttom_text_blockchain: '存证信息',
  buttom_text_verification: '审核信息',

  description_custom_id:
    'ID列表只能录入同类型ID（如：已输入VID，则不能添加自定义ID，清除所有ID可重新选择ID类型）',
  custom_id: '自定义ID',
  vid: 'VID',
  empty_id_list_hint: '请点击右上角添加按钮添加ID',

  add_id_dialog_mode_qr: '二维码',
  add_id_dialog_mode_chip: '芯片',
  add_id_dialog_title: '录入标签',
  add_id_bigvid_title: '录入批次',
  add_id_dialog_type: '录入方式',
  add_id_dialog_order_type: '订单选择',
  add_id_dialog_custom_id_valid: '可用数量',
  add_id_dialog_custom_id_use: '使用数量',
  add_id_dialog_start_end_type: '按起止码输入自定义ID',
  add_id_dialog_input_type: '输入自定义ID',
  add_id_dialog_beginning_id: '起始码',
  add_id_dialog_end_id: '终止码',
  add_id_dialog_beginning_id_placeholder: '请输入起始码',
  add_id_dialog_end_id_placeholder: '请输入终止码',
  add_id_dialog_end_error_id: '自定义ID不合法或已使用',
  add_batch_dialog_end_error_id: '请扫描批次码收货',
  add_id_dialog_limit_error: '数量已超上限',
  add_error_id_has_been_used: 'ID已使用',
  dcp_ref_title: '数据引用',
  dcp_ref_filter_name: '通过名称搜索',
  dcp_ref_filter_model: '全部模板',
  dcp_ref_table_item_model: '模板名称',
  dcp_ref_table_item_org: '机构名称',
  dcp_ref_all_table_title: '引用数据',
  dcp_ref_added_table_title: '已选数据',
  no_receive_permissions: "无收货权限",
  gps_refresh_button: '刷新'

}
