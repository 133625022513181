const feture = {
  base_info_success: '产品基本信息验证成功',
  prd_trace_success: '产品追溯信息验证成功',
  spt_info_success: '供应商信息验证中',
  collect: '收起',
  expand: '展开',
  prd_info: '商品信息',
  bc_rd: '区块链记录',
  brd_info: '品牌信息',
  logistics_info: "物流信息",
  quca_lince: '资质证照',
  trace_info: '追溯信息',
  prd_rpt: '生产报告',
  blc_dept: '区块链数据存证',
  dis_now: '即刻探索',
  sub_failed: '提交失败，请重新提交数据',
  fill_msg: '寄语填写',
  no_data: '暂无数据',
  chk_review: '提交成功系统审核中，请您稍后查看',
  ecs_bless: '专属祝福',
  prd_tag: '商品标签',
  prd_search: '商品查询记录',
  enquiries: '查询次数',
  modal_bless: '为Ta定制专属祝福',
  choose_form: '请选择您的祝福形式',
  skip: '跳过',
  photo_bless: '图片祝福',
  fill_wish: '请填写您的祝福',
  vioce_bless: '语音祝福',
  video_bless: '视频祝福',
  plse_sel_video: '请选择视频',
  plse_sel_photo: '请选择照片',
  plse_add_voice: '请添加语音',
  please_title: '请输入标题',
  please_content: '请输入内容',
  next: '下一步',
  smt: '提交',
  bck_dpt: '区块链存证',
  prd_code: '商品编码',
  upload_id: '上传身份',
  upload_time: '上传时间',
  block: '所在区块',
  bck_time: '成块时间',
  trace_num: '交易编号',
  miss_vid: 'vid 不存在',
  miss_customId: 'customId 不存在',
  msg: '寄语信息',
  img_empty_tip: '图片数据不能为空，请先上传',
  video_empty_tip: '视频数据不能为空，请先上传',
  voice_tip: '语音祝福必要数据不能为空，请先上传',
  msg_empty: '寄语祝福名称不能为空',
  content_empty: '寄语祝福内容不能为空',
  failed_get_msg: '获取寄语详情失败',
  failed_get_code: '获取赋码追溯详情失败',
  failed_get_brand: '获取品牌故事详情失败',
  records: '扫码记录',
  tag_searched: '您所查询的标签已被查询',
  time: '次',
  first_check: '首查时间',
  first_location: '首查地点',
  no_record: '暂无扫码记录',
  more: '加载更多',
  no_more: '没有更多数据',
  query: '次查询',
  no: '第',
  scan_record_failed: '获取扫码记录失败',
  prd_loc: '生产所在地',
  audio_error: '音频文件格式不对，需要mp3文件，请重新上传',
  reupload: '请重新上传',
  uploading: '文件上传中...',
  again: '请重试',
  upload_failed: '文件上传失败，请重试',
  get_sku_failed: '获取SKU模版数据失败,请重试',
  params_error: '通知app, 参数错误',
  create_success: '信息创建成功',
  token_failed: 'token 解析失败',
  failed_cert_deposit: '获取区块链存证失败',
}

export default feture;
